import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
//Select imports from material ui
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";
import { sanitizeHtml } from "../domPurify/domPurify";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 0,
  },
  sizeInc: {
    width: "150%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

const AddFactor = () => {
  console.clear();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };
  // States
  const [factorData, setFactorData] = useState([]);
  // form states
  const [factor, setFactor] = useState("");

  // Functions
  const getFactors = async () => {
    let api = "/factors/SelectFactor";
    let fd = await userRequestForOfs.get(api);
    setFactorData(fd.data.data);
    console.log("factor data ===>", fd.data.data);
  };
  useEffect(() => {
    getFactors();
  }, []);

  function formClear() {
    setFactor("");
  }

  // Sanitize Inputs from xss attacks
  const sanitizedFactor = sanitizeHtml(factor);

  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log(getCurrentMonth);

  //Form Submit Handler
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!factor) {
      toast.error("Please write factor!");
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);

      return;
    }

    console.log("factor ==>", factor);
    setValidated(true);
    setIsDisabled(true);

    userRequestForOfs
      .post("/factors/createFactor", {
        factor: factor,
        submittedBy: localStorage.getItem("username"),
      })
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          setOpenR(false);
          formClear();
          getFactors();
          setValidated(false);
          toast.success("Inserted successfully !");
          setIsDisabled(false);
          console.log("ddddddddddddd" + res.status);
          console.log(res.data.message);
        } else {
          setOpenR(true);
          console.log(res.data.message);
          toast.error("Error !");
        }
      })
      .catch((error) => {
        // error from catch
        console.log("error from catch ==>", error);
        if (error.message === "Network Error") {
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false);
          return;
        } else if (error.response.status === 409) {
          toast.error("Factor already Exist");
          setIsDisabled(false);
          return;
        } else {
          toast.error("Error !");
          return;
        }
      });
  };
  return (
    <div className="DHContainer">
      <div>
        <Button
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Factor
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
              width: "600px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Add Factor
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Form
              noValidate
              validated={validated}
              className="mobile-view-form"
              onSubmit={submitHandler}
            >
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom03"
                >
                  <div>
                    <InputLabel id="demo-simple-select-label" className="w-100">
                      Factor
                    </InputLabel>
                    <FormControl
                      required
                      className={`w-100 ${classes.formControl}`}
                    >
                      <Form.Group
                        as={Col}
                        md="8"
                        className="w-100"
                        controlId="validationCustom01"
                      >
                        {/* <Form.Label>Name :</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Enter Factor Name"
                          className="w-100"
                          value={sanitizedFactor}
                          onChange={(e) => setFactor(e.target.value)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Factor name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </FormControl>
                  </div>
                </Form.Group>
              </Row>
              {/* <div className='w-100 d-flex justify-content-between'>
                                <Button
                                    className='dialog-btn close-btn-abc'
                                    onClick={() => handleCloseR()}
                                >
                                    Close
                                </Button>
                                <Button
                                    className='dialog-btn'
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div> */}
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      <div style={{ maxWidth: "100%", marginTop: "1rem" }}>
        <MaterialTable
          title="Factors"
          columns={[
            // {
            //     field: "id",
            //     title: "ID",
            //     cellStyle: {
            //         backgroundColor: "#74F700",
            //         color: "#FFF",
            //     },
            //     headerStyle: {
            //         backgroundColor: "#74F700",
            //     },
            // },
            { field: "factor", title: "Factor Name" },
          ]}
          data={factorData}
          //update delete work
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...factorData];
                  const index = oldData.id;

                  // console.log(index)
                  dataUpdate[index] = newData;
                  userRequestForOfs
                    .post("/factors/updateFactor", newData)
                    .then((res) => {
                      if (res.status === 200) {
                        toast.success("Factor Update Successfully");
                        console.log("updatedSuccessfully");
                        console.log(newData);
                        getFactors();
                      }
                      //  else {
                      //     console.log(res.data.message);
                      //     console.log(newData);
                      //     toast.error("Error !");

                      // }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false);
                        return;
                      } else if (error.response.status === 409) {
                        toast.error("Factor already Exist");
                        setIsDisabled(false);
                        return;
                      } else {
                        toast.error("Error !");
                        return;
                      }
                    });
                  // setCauseData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  userRequestForOfs
                    .post("/factors/deleteFactor", {
                      id: index,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("updatedSuccessfully");
                        // console.log(newData);
                        getFactors();
                        toast.info("Factor Delete Successfully");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false);
                        return;
                      } else {
                        toast.error("Error !");
                        return;
                      }
                    });

                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            exportButton: true,
            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
            headerStyle: {
              backgroundColor: "#77C9C1",
              color: "black",
            },
          }}
        />
      </div>
    </div>
  );
};

export default AddFactor;
