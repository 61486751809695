import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "150%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
 
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));


export default function ViewFeedback() {
  console.clear()
  const columns = [
    // {
    //   field: "id",
    //   title: "ID",
    //   cellStyle: {
    //     backgroundColor: "#74F700",
    //     color: "#FFF",
    //   },
    //   headerStyle: {
    //     backgroundColor: "#74F700",
    //   },
    // },
    { field: "Department", title: "Department" },
    { field: "RelatedTo", title: "Related to" },
    { field: "Category", title: "Category" },
    { field: "Message", title: "Message/Feedback" },
    { field: "PositiveOrNegative", title: "Positive/Negative" },
  ];

  const [data, setData] = useState([
    {
      id: 1,
      Department: "Shipping",
      RelatedTo: "HR",
      Category: "Abc",
      Message: "Products are shipped within time",
      PositiveOrNegative: "Positive",
    },
    {
      id: 2,
      Department: "Packing",
      RelatedTo: "HR",
      Category: "Abc",
      Message: "Packing of products is well organized",
      PositiveOrNegative: "Positive",
    },
    {
      id: 3,
      Department: "Casing",
      RelatedTo: "HR",
      Category: "Abc",
      Message: "Casing of products is taking too much time",
      PositiveOrNegative: "Negative",
    },
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
 
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [SelectAnamoly, setAnamoly] = useState("");
  const [DefactStat, setDefactStat] = useState("");
  const [AmDetail, setAmDetail] = useState("");
  const [Respon, setRespon] = useState("");
  const [ActionPlan, setActionPlan] = useState("");
  const [TD, setTD] = React.useState(new Date());
  const [tableDATA, setTableDATA] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedMachine, setSelectedMachine] = useState("");
  const [line, setLine] = useState("");
  const [hfound, setHfound] = useState("");
  const [wfound, setWfound] = useState("");
  const [priority, setPriority] = useState("");

  const handleChange = (event) => {
    setAnamoly(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setDefactStat(event.target.value);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function testingData() {
    console.log(tableDATA.data);
  }
  testingData();

  //Fetching Data for Table
  //   const defectData = async () => {
  //     const fetchedData = await axios.get(
  //       "https://come.pakistanautomation.com.pk/api/defects/selectDefect"
  //     );
  //     setTableDATA(fetchedData.data);
  //     setLoading(false);
  //   };
  //   //Fetching Data For Departements
  //   const getdepartments = async () => {
  //     const fetchedData = await axios.get(
  //       "https://come.pakistanautomation.com.pk/api/departments/getDepartment"
  //     );
  //     console.log(fetchedData.data);
  //     setDepartmentData(fetchedData.data.department);
  //   };
  //   //Fetching Data For Machines
  //   const getmachines = async () => {
  //     const fetchedData = await axios.get(
  //       "https://come.pakistanautomation.com.pk/api/machines/getMachines"
  //     );
  //     console.log(fetchedData.data);
  //     setMachineData(fetchedData.data.machines);
  //   };
  // let testData = [
  //     {
  //         key: 1,
  //         value: 'plantA'
  //     },
  //     {
  //         key: 1,
  //         value: 'plantB'
  //     },
  //     {
  //         key: 1,
  //         value: 'plantC'
  //     },
  //     {
  //         key: 1,
  //         value: 'plantD'
  //     }
  // ]

  // let optionItems = testData.map((planet) =>
  //     <MenuItem key={planet.key}>{planet.value}</MenuItem>
  // );

  useEffect(() => {
    // defectData();
    // getdepartments();
    // getmachines();
  }, []);

  //Form Submit Handler
  const submitHandler = (e) => {
    e.preventDefault();
    console.log({
      department: selectedDepartment,
      machine: selectedMachine,
      line: line,
      hfound: hfound,
      wfound: wfound,
      priority: priority,
      anamoly: SelectAnamoly,
      defectStatus: DefactStat,
      anamolyDetail: AmDetail,
      responsible: Respon,
      actionPlan: ActionPlan,
      targetDate: TD,
    });
    //   .post("https://come.pakistanautomation.com.pk/api/defects/createDefect", {
    //     department: selectedDepartment,
    //     machine: selectedMachine,
    //     line: line,
    //     hfound: hfound,
    //     wfound: wfound,
    //     priority: priority,
    //     anamoly: SelectAnamoly,
    //     defectStatus: DefactStat,
    //     anamolyDetail: AmDetail,
    //     responsible: Respon,
    //     actionPlan: ActionPlan,
    //     targetDate: TD,
    //     closeDate: CD,
    //     createdOn: new Date(),
    //     createdBy: localStorage.getItem("username"),
    //   })
    //   .then((res) => {
    //     console.log(res);
    //     if (res.status === 201) {
    //       setOpenR(false);
    //       defectData();
    //       // console.log(tableDATA)
    //       console.log(res.data.message);
    //       console.log("ddddddddddddd" + res.status);
    //     } else {
    //       setOpenR(true);
    //       console.log(res.data.message);
    //     }
    //   });
  };
  return (
    <div className="DHContainer">
      <MaterialTable
        style={{
          marginTop: "10px",
          whiteSpace: "nowrap",
          maxWidth: "90%",
          overflowX: "scroll",
          overflowY: "scroll",
          position: "absolute",
        }}
        title=" View Feedback"
        columns={columns}
        data={data}
        //update and delete work
        


       
        options={{
          filtering: true,
          exportButton: true,
          // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
          headerStyle: {
            backgroundColor: "#77C9C1",
            color: "black",
        },
        }}
      />

      {/* <button onClick={testingData}>somme</button> */}
    </div>
  );
}
