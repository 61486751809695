import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
// import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//Select imports from material ui
import InputLabel from "@material-ui/core/InputLabel";
// import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  Form,
  Row,
  Col,
  ToastHeader,
  // InputGroup
} from "react-bootstrap";
import { toast } from "react-toastify";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";
import { sanitizeHtml } from "../domPurify/domPurify";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "100%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));
const SetOfs = () => {
  // console.clear()

  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [openR, setOpenR] = React.useState(false);
  const [openD, setopenD] = useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };

  //openD funnction declaration
  const handleOpenD = () => {
    setopenD(true);
  };
  const handleCloseD = () => {
    setopenD(false);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };
  // States
  const [ofsData, setSetOfsData] = useState([]);
  // form states
  const [departName, setDepartName] = useState("");
  const [userInputData, setUserInputData] = useState("");
  const [setBy, setSetBy] = useState("");
  const [ofsValue, setOfsValue] = useState("");
  const [CD, setCD] = useState(new Date());
  const [departData, setDepartData] = useState([]);

  const [addOfsValue, setAddOfsValue] = useState();

  // Functions
  const getSetOFS = async () => {
    let api = "/setOfs/SelectSetOfs";
    let fd = await userRequestForOfs.get(api);
    setSetOfsData(fd.data.data);
    console.log("setOFS data ===>", fd.data.data);
  };

  const getDepart = async () => {
    let api = "/department/SelectDepartment";
    let fd = await userRequestForOfs.get(api);
    setDepartData(fd.data.data);
    console.log("dept data ===>", fd.data.data);
  };

  useEffect(() => {
    getSetOFS();
    getDepart();
  }, []);

  function formClear() {
    setDepartName("");
    setOfsValue("");
    setSetBy("");
    setCD(new Date());
    setAddOfsValue("");
  }

  // Sanitize Inputs from xss attacks
  const sanitizeofsValue = sanitizeHtml(ofsValue);
  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log("getCurrentMonth ==== >", getCurrentMonth);

  const [ofsobj, setOfsObj] = useState({});
  // Add ofs value function
  const AddofsValueDialog = (data) => {
    setopenD(true);
    setOfsObj(data);
  };

  console.log(ofsobj);

  //add ofs value submit handler
  const addOfsSubmitHandler = (e) => {
    e.preventDefault();
    setIsDisabled(true);

    //console.log(ofsobj)
    userRequestForOfs
      .post("/setOfs/addSetOfs", {
        id: ofsobj.id,
        DepartName: ofsobj.DepartName,
        setBy: ofsobj.SetBy,
        Date: ofsobj.Date,
        ofsValue: ofsobj.ofsValue,
        AddofsValue: addOfsValue,
      })
      .then((res) => {
        console.log("messageeeeeee", res.message);

        if (res.status === 200) {
          setopenD(false);
          formClear();
          getSetOFS();
          setValidated(false);
          setIsDisabled(false);
          setopenD(false);
          toast.success("Data Inserted successfully");

          // console.log(tableDATA)
          console.log(res.data.message);
          console.log("ddddddddddddd" + res.status);
        }
      })
      .catch((error) => {
        // error from catch
        console.log("error from catch ==>", error);
        if (error.message === "Network Error") {
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false);
          return;
        } else if (error.response.status === 409) {
          toast.error("Already Exist");
          setIsDisabled(false);
          return;
        } else {
          toast.error("Error !");
          return;
        }
      });
  };

  //Form Submit Handler
  const submitHandler = (e) => {
    //for department validity
    if (!departName) {
      toast.error("Please fill all the fields");
      e.preventDefault();
      e.stopPropagation();
      setIsDisabled(false);

      return;
    }
    //for
    e.preventDefault();
    console.log("before submit req ==>", departName, ofsValue);
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      toast.error("Please fill all the fields");
      return;
    }
    // if (departName.length == 0 && ofsValue.length === 0) {
    //     toast.error("Please fill input fields!");
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);
    //     return;
    // }
    setValidated(true);
    setIsDisabled(true);

    //Logic for checking if department already exist in Current Month
    let arrayExist = [];
    let isExist = 0;
    let checker = ofsData.map((item) => {
      console.log(moment().format("MM-YYYY"));
      console.log("inside Checker", item.DepartName);
      console.log("inside Checker", departName);
      console.log(moment(item.Date, "DD-MM-YYYY").format("MM-YYYY"));
      if (
        moment(item.Date, "DD-MM-YYYY").format("MM-YYYY") ===
          moment().format("MM-YYYY") &&
        item.DepartName === departName
      ) {
        console.log(true);
        console.log("Department Already Exist");
        isExist = 1;
        arrayExist.push(isExist);
        console.log(isExist);
      } else {
        console.log(false);
        isExist = 0;
        arrayExist.push(isExist);
        console.log(isExist);
        console.log("Department Didnt Exist");
      }
    });
    console.log(checker);
    console.log(isExist);
    console.log(arrayExist);
    if (arrayExist.includes(1)) {
      toast.warn("OFS Already Assigned For This Month");
      setOpenR(true);
      // formClear();
      setValidated(false);
      setIsDisabled(false);
      return;
    }
    //Post Call Start
    userRequestForOfs
      .post("/setOfs/createSetOfs", {
        DepartName: departName,
        ofsValue: ofsValue,
        Date: CD,
        setBy: localStorage.getItem("username"),
      })
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          setOpenR(false);
          formClear();
          getSetOFS();
          setValidated(false);
          toast.success("Data Inserted successfully");
          setIsDisabled(false);

          console.log(res.data.message);
          console.log("ddddddddddddd" + res.status);
        }
        // else {
        //     setOpenR(true);
        //     toast.error("error from server")
        //     console.log(res.data.message);
        // }
      })
      .catch((error) => {
        // error from catch
        console.log("error from catch ==>", error);
        if (error.message === "Network Error") {
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false);
          return;
        } else if (error.response.status === 409) {
          toast.error("Already Exist");
          setIsDisabled(false);
          return;
        } else {
          toast.error("Error !");
          return;
        }
      });

    //Post Call End
  };

  return (
    <div className="DHContainer">
      <div>
        <Button
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Set OFS
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
              width: "600px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Add Set OFS
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Form
              noValidate
              validated={validated}
              className="mobile-view-form"
              onSubmit={submitHandler}
            >
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom02"
                >
                  <Form.Label>OFS Value :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    placeholder="OFS Value"
                    className="w-100"
                    value={sanitizeofsValue}
                    onChange={(e) => setOfsValue(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid OFS value.
                  </Form.Control.Feedback>
                  <br />

                  <InputLabel id="demo-simple-select-label" className="w-100">
                    Department
                  </InputLabel>
                  <Select
                    className={`w-90 ${classes.sizeInc}`}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={departName}
                    onChange={(e) => setDepartName(e.target.value)}
                  >
                    {departData.map((item) => (
                      <MenuItem value={item.DepartName}>
                        {item.DepartName}
                      </MenuItem>
                    ))}
                  </Select>
                </Form.Group>

                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      views={["month", "year"]}
                      disableFuture={true}
                      disablePast={true}
                      margin="normal"
                      id="datee-picker-dialog"
                      label="Start Date"
                      format="yyyy-MM-dd"
                      value={CD}
                      onChange={setCD}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Row>
              {/* <div className='w-100 d-flex justify-content-between'>
                                <Button
                                    className='dialog-btn close-btn-abc'
                                    onClick={() => handleCloseR()}
                                >
                                    Close
                                </Button>
                                <Button
                                    className='dialog-btn'
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div> */}
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
        {/* dialouge open for add ofs  */}
        <Dialog
          fullScreen={fullScreen}
          open={openD}
          onClose={handleCloseD}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Add OFS
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Form
              noValidate
              validated={validated}
              className="mobile-view-form"
              onSubmit={addOfsSubmitHandler}
            >
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom02"
                >
                  <Form.Label>OFS Value :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    placeholder="OFS Value"
                    className="w-100"
                    value={addOfsValue}
                    onChange={(e) => setAddOfsValue(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid OFS value.
                  </Form.Control.Feedback>
                  <br />
                </Form.Group>
              </Row>
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseD()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      <div style={{ maxWidth: "100%", marginTop: "1rem" }}>
        <MaterialTable
          title="SET OFS"
          columns={[
            { field: "DepartName", title: "Depart Name", editable: "never" },
            { field: "SetBy", title: "Set By", editable: "never" },
            { field: "Date", title: "Date", editable: "never" },
            { field: "ofsValue", title: "OFS Value" },
          ]}
          data={ofsData}
          //update delete work

          editable={{
            // onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //         setTimeout(() => {
            //             const dataUpdate = [...ofsData];
            //             const index = oldData.id;

            //             // console.log(index)
            //             dataUpdate[index] = newData;
            //
            //                 .post(
            //                     "https://ofs-b.finehhdigital.com/api/setOfs/updateSetOfs",
            //                     newData
            //                 )
            //                 .then((res) => {
            //                     if (res.status === 200) {
            //                         console.log("updatedSuccessfully");
            //                         getSetOFS();

            //                         console.log(newData);

            //                     } else {
            //                         console.log(res.data.message);
            //                         console.log(newData);
            //                         toast.error("Error !");

            //                     }
            //                 });
            //             // setCauseData([...dataUpdate]);
            //             resolve();
            //         }, 1000);
            //     }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  userRequestForOfs
                    .post("/setOfs/deleteSetOfs", {
                      id: index,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("updatedSuccessfully");
                        // console.log(newData);
                        getSetOFS();
                        toast.info("Delete successfully !");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false);
                        return;
                      } else {
                        toast.error("Error !");
                        return;
                      }
                    });

                  resolve();
                }, 1000);
              }),
          }}
          actions={[
            {
              icon: "+",
              tooltip: "Update OFS Value",
              onClick: (event, rowData) => AddofsValueDialog(rowData),
            },
          ]}
          options={{
            filtering: true,
            exportButton: true,
            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
            headerStyle: {
              backgroundColor: "#77C9C1",
              color: "black",
            },
          }}
        />
      </div>
    </div>
  );
};

export default SetOfs;
