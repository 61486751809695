import React, { Fragment, useEffect, useState } from "react";
import jwt, { decode } from "jsonwebtoken";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";
import { sanitizeHtml } from "../domPurify/domPurify";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 280,
  },
  media: {
    height: 140,
  },
  cardmedia: {
    width: "120px",
    height: "120px",
    margin: "auto",
  },
  cardmediaOFS: {
    width: "80px",
    height: "80px",
    marginLeft: "auto",
    marginRight: "auto",
    // paddingBottom: '0px'
  },

  //form classes
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: "65%",
    // minWidth: "120",
    // maxWidth: 600
    width: "100%",
    // marginLeft: 0
  },
  sizeInc: {
    width: "100%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
  roots: {
    "& > *": {
      margin: theme.spacing(1),
      display: "flex",
      // border: '1px solid black',
    },
  },
}));
export default function Cards() {
  // console.clear()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [role, setRole] = useState();
  // console.log("role ==> ", role);
  const [userId, setUserId] = useState();
  // console.log("userId ==> ", userId);
  const [userData, setUserData] = useState([]);
  // console.log("userData ====>", userData);

  // form states
  const [feedbackData, setFeedbackData] = useState();
  const [instructorDept, setInstructorDept] = useState("");
  const [behaviorDept, setBehaviorDept] = useState();
  const [location, setLocation] = useState();
  const [locationData, setLocationData] = useState([]);
  const [behavior, setBehavior] = useState();
  const [factor, setFactor] = useState();
  const [factorData, setFactorData] = useState([]);
  const [appreciated, setAppreciated] = useState();
  const [response, setResponse] = useState("");
  const [depData, setDepData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openR, setOpenR] = React.useState(false);

  // for bootstrap alert
  const [isAlert, setIsAlert] = useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  console.log("userIndividualData ==> ", userData);
  const history = useHistory();
  const classes = useStyles();
  const handlePageRedirect = () => {
    window.location.href = "https://finehhdigital.com/home";
  };

  //state for input location
  const [inputLocation, setInputLocation] = useState(false);

  //state for response
  var locationTextOpen1 = (e) => {
    setLocation(e);
    console.log("e ==>", e);
    if (e === "Other") {
      setInputLocation((preVal) => !preVal);
    }
  };

  var handleTextLocationOpen2 = () => {
    setInputLocation((previousValue) => !previousValue);
    setLocation("");
  };

  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log(getCurrentMonth);

  function formClear() {
    // setDepart("");
    // setInstructorDept("");
    setResponse("");
    // setComments("");
    setAppreciated("");
    setFactor("");
    setLocation("");
    setBehavior("");
    setBehaviorDept("");
    getUserIndividualData();
    // setFactorData([])
    // setLocationData([])
  }

  
  // Sanitize Inputs from xss attacks
  const sanitizeBehavior= sanitizeHtml(behavior);


  const getUserIndividualData = async () => {
    // console.log("function running getUserIndividualData ==>");
    // console.log("function running getUserIndividualData ==>", localStorage.getItem("id"));
    await userRequestForOfs
      .post("/users/userIndividualData", {
        id: userId,
      })
      .then((res) => {
        // toast.success("Inserted successfully !");
        console.log("response from getUserIndividualData ====>", res);
        if (res.status === 200) {
          // console.log(res.data)
          setUserData(res.data.data);
          console.log("with data.data", res.data.data);
          // console.log(res.data.message);
          // console.log("ddddddddddddd" + res.status);
        } else {
          console.log(res.data.message);
          // toast.error("Error !");
        }
      });
  };

  const getFeedback = async () => {
    let api = "/feedback/getFeedback";
    const fetchedData = await userRequestForOfs.get(api);
    console.log("get feedback data ===>", fetchedData.data.data);
    setFeedbackData(fetchedData.data.data);
  };

  //for getting department data
  const getDepData = async () => {
    let FD = await userRequestForOfs.get(
      "/department/SelectDepartment"
    );
    console.log("relatedto==>", FD.data.data);
    setDepData(FD.data.data);
  };
  const getFactor = async () => {
    let FD = await userRequestForOfs.get(
      "/factors/selectfactor"
    );
    console.log("getfactorssdata==>", FD.data.data);
    setFactorData(FD.data.data);
  };

  const getLocation = async (DepartmentName) => {
    let FD = await userRequestForOfs.post(
      "/location/getDepartmentLocation",
      { department: DepartmentName }
    );
    console.log("getLocation data==>", FD.data.data);
    setLocationData(FD.data.data);
  };

  const HandleBehaviourDepart = (e) => {
    setBehaviorDept(e.target.value);
    getLocation(e.target.value);
  };

  // Checking UserData ofs Remaining Value

  let UserOfsLeft = userData.map((item) => {
    var ofsValue = item.remainingOfs;
    return ofsValue;
  });
  console.log(UserOfsLeft[0]);
  //Form Submit Handler

  const submitHandler = (e) => {
    console.log("checkingValue", UserOfsLeft[0]);
    console.log("instructor Dept ====>", instructorDept);
    //  Checking OFS value
    if (UserOfsLeft[0] > 0) {
      e.preventDefault();

      if (
        !instructorDept ||
        !location ||
        !factor ||
        !behaviorDept ||
        !response ||
        !appreciated ||
        !behavior
      ) {
        toast.warn("Fill All Fields");
        return;
      }

      // OfsValue++
      // console.log("ofsValue -===> ", OfsValue);

      // count++;
      // console.log("testing", count);
      setIsDisabled(true);

      userRequestForOfs
        .post("/feedback/sendFeedback", {
          // department: depart,
          location: location,
          factor: factor,
          instructorDept: instructorDept,
          behaviorDept: behaviorDept,
          appreciated: appreciated,
          // comments: comments,
          behavior: behavior,
          response: response,
          submittedBy: localStorage.getItem("username"),
          submittedMonth: getCurrentMonth,
          submittedOn: moment().format("DD-MM-YYYY"),
          ofsValue: 1,
          id: userId,
        })
        .then((res) => {
          console.log("response from server", res);
          setIsDisabled(false);
          // getFeedback();
          if (res.status === 200) {
            toast.success("Feedback insert successfully ");

            setOpenR(false);
            getFeedback();
            formClear();
            // console.log(tableDATA)
            console.log(res.data.message);
            console.log("ddddddddddddd" + res.status);
          } else if (res.status === 403) {
            toast.warning("your ofs completed");
            setOpenR(false);
            formClear();
            console.log(res.data.message);
          }
          // else if(res.message==='Network Error'){
          //   console.log("messagggeeee",res.message);
          //   setIsDisabled(false)
          //   toast.error('Network Error')}
          else {
            setOpenR(true);
            console.log(res.data.message);
          }
        })
        .catch((res) => {
          if (res.message === "Network Error") {
            toast.error("Server Error!");
            setIsDisabled(false);
            setOpenR(true);
          }
        });
    } else {
      e.preventDefault();
      console.log("achived users");

      if (
        !instructorDept ||
        !location ||
        !factor ||
        !behaviorDept ||
        !response ||
        !appreciated ||
        !behavior
      ) {
        toast.warn("Fill All Fields");
        return;
      }
      setIsDisabled(true);

      userRequestForOfs
        .post("/feedback/sendFeedback", {
          location: location,
          factor: factor,
          instructorDept: instructorDept,
          behaviorDept: behaviorDept,
          appreciated: appreciated,
          behavior: behavior,
          response: response,
          submittedBy: localStorage.getItem("username"),
          submittedMonth: getCurrentMonth,
          submittedOn: moment().format("DD-MM-YYYY"),
          ofsValue: 1,
          id: userId,
        })
        .then((res) => {
          console.log("submi ofs but target Achived ====>  ", res);
          setIsDisabled(false);
          if (res.status === 200) {
            toast.success("Feedback insert successfully ");
            setOpenR(false);
            getFeedback();
            formClear();
            console.log(res.data.message);
          } else {
            setOpenR(true);
            console.log(res.data.message);
          }
        })
        .catch((res) => {
          if (res.message === "Network Error") {
            toast.error("Server Error!");
            setIsDisabled(false);
            setOpenR(true);
          }
        });
    }
  };

  // const submitHandler = (e) => {
  //   console.log("checkingValue", UserOfsLeft[0]);
  //   console.log("instructor Dept ====>", instructorDept);
  //   //  Checking OFS value
  //   if (
  //     !instructorDept ||
  //     !location ||
  //     !factor ||
  //     !behaviorDept ||
  //     !response ||
  //     !appreciated ||
  //     !behavior
  //   ) {
  //     toast.warn("Fill All Fields");
  //     return;
  //   }
  //   setIsDisabled(true);

  //   
  //     .post("https://ofs-b.finehhdigital.com/api/feedback/sendFeedback", {
  //       // department: depart,
  //       location: location,
  //       factor: factor,
  //       instructorDept: instructorDept,
  //       behaviorDept: behaviorDept,
  //       appreciated: appreciated,
  //       // comments: comments,
  //       behavior: behavior,
  //       response: response,
  //       submittedBy: localStorage.getItem("username"),
  //       submittedMonth: getCurrentMonth,
  //       ofsValue: 1,
  //       id: userId,
  //     })
  //     .then((res) => {
  //       console.log("response from server", res);
  //       setIsDisabled(false);
  //       // getFeedback();
  //       if (res.status === 200) {
  //         toast.success("Feedback insert successfully ");

  //         setOpenR(false);
  //         getFeedback();
  //         formClear();
  //         // console.log(tableDATA)
  //         console.log(res.data.message);
  //         console.log("ddddddddddddd" + res.status);
  //       } else if (res.status === 403) {
  //         toast.warning("your ofs completed");
  //         setOpenR(false);
  //         formClear();
  //         console.log(res.data.message);
  //       } else {
  //         setOpenR(true);
  //         console.log(res.data.message);
  //       }
  //     })
  //     .catch((res) => {
  //       if (res.message === "Network Error") {
  //         toast.error("Server Error!");
  //         setIsDisabled(false);
  //         setOpenR(true);
  //       }
  //     });
  // };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    console.log(decoded);
    setUserId(decoded.payload.id);
    setRole(decoded.payload.ofsRole);
    setInstructorDept(decoded.payload.deptName);
    getUserIndividualData();
    // dataFilter()
    getDepData();
    getFactor();
  }, [userId]);

  return (
    <div style={{ height: "80vh" }}>
      <div className="centerHeading">
        <h1>ONGOING FEEDBACK SYSTEM</h1>{" "}
      </div>

      <div className="breadcrumb" onClick={handlePageRedirect}>
        <div>
          <img src="/icon/home1.png" alt="homeLogo"></img>
        </div>
        <div>Home</div>
      </div>
      <div>
        <Button
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          style={{ margin: "0.5rem" }}
          startIcon={<AddCircleOutlineIcon />}
        >
          Submit Feedback
        </Button>
        <Dialog
          className="feedbackdia"
          fullScreen={fullScreen}
          // fullScreen
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
              // width: '600px'
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title" className="">
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              FEEDBACK
            </Typography>
          </DialogTitle>

          <DialogContent className="resp-modal-for-mobile w-100 mx-0 px-4 ">
            <form className="dhForm" noValidate onSubmit={submitHandler}>
              <Row className="mb-3">
                <div>
                  {/* <Form.Group as={Col} md="8" className='w-100' controlId="validationCustom01"> */}
                  {/* <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <div style={{ marginBottom: "2rem" }}>
                        <InputLabel
                          className="Form-Label"
                          id="demo-simple-select-label"
                        >
                          Your department ?
                        </InputLabel>
                      </div>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={instructorDept}
                        onChange={(e) => setInstructorDept(e.target.value)}
                      >
                        {depData.map((item) => (
                          <MenuItem value={item.DepartName}>
                            {item.DepartName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Form.Group> */}
                  {/* </Form.Group band> */}
                </div>
                <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <div style={{ marginBottom: "2rem" }}>
                        <InputLabel
                          className="Form-Label"
                          id="demo-simple-select-label"
                        >
                          The behavior is found in which department?
                        </InputLabel>
                      </div>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={behaviorDept}
                        onChange={(e) => HandleBehaviourDepart(e)}
                      >
                        {depData.map((item) => (
                          <MenuItem value={item.DepartName}>
                            {item.DepartName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Form.Group>
                </div>
                <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      {inputLocation ? null : (
                        <>
                          <InputLabel
                            className="Form-Label"
                            id="demo-simple-select-label"
                          >
                            Location?
                          </InputLabel>
                          <Select
                            className={classes.sizeInc}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location}
                            onChange={(e) => locationTextOpen1(e.target.value)}
                          >
                            {locationData.map((item) => (
                              <MenuItem value={item.location}>
                                {item.location}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      {inputLocation ? (
                        <>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              required
                            />
                          </Form.Group>

                          <div>
                            {/* <Button onClick={(e) => handleTextLocationOpen2(e)} className="border border-danger"> */}
                            <Button onClick={(e) => handleTextLocationOpen2(e)}>
                              Remove
                            </Button>
                          </div>
                        </>
                      ) : null}
                    </FormControl>
                  </Form.Group>
                </div>

                {/* <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        className="Form-Label"
                        id="demo-simple-select-label"
                      >
                        Location?
                      </InputLabel>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                      >
                       
                        {locationData.map((item) => (
                          <MenuItem value={item.location}>
                            {item.location}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Form.Group>
                </div> */}

                <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        className="Form-Label"
                        id="demo-simple-select-label"
                      >
                        Factor
                      </InputLabel>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={factor}
                        onChange={(e) => setFactor(e.target.value)}
                      >
                        {factorData.map((item) => (
                          <MenuItem value={item.factor}>{item.factor}</MenuItem>
                        ))}

                        {/* <MenuItem value={"FL"}>FL</MenuItem>
                        <MenuItem value={"PPEs"}>PPEs</MenuItem>
                        <MenuItem value={"Mobile Phone"}>Mobile Phone</MenuItem>
                        <MenuItem value={"FF Elements"}>FF Elements</MenuItem>
                        <MenuItem value={"5s on Floor"}>5s on Floor</MenuItem>
                        <MenuItem value={"5S on Tables"}>5S on Tables</MenuItem>
                        <MenuItem value={"Handling"}>Handling</MenuItem>
                        <MenuItem value={"Working at Height"}>Working at Height</MenuItem>
                        <MenuItem value={"Hot Work"}>Hot Work</MenuItem>
                        <MenuItem value={"LOTO"}>LOTO</MenuItem>
                        <MenuItem value={"Air Gun"}>Air Gun</MenuItem>
                        <MenuItem value={"Pathways"}>Pathways</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Form.Group>
                </div>

                <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        className="Form-Label"
                        id="demo-simple-select-label"
                      >
                        Safe or Unsafe
                      </InputLabel>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={response}
                        onChange={(e) => setResponse(e.target.value)}
                      >
                        <MenuItem value={"Safe"}>Safe</MenuItem>
                        <MenuItem value={"Unsafe"}>Unsafe</MenuItem>
                      </Select>
                    </FormControl>
                  </Form.Group>
                </div>

                <div>
                  <Form.Group
                    as={Col}
                    md="8"
                    className="w-100"
                    controlId="validationCustom01"
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        className="Form-Label"
                        id="demo-simple-select-label"
                      >
                        Appreciated/Corrected
                      </InputLabel>
                      <Select
                        className={classes.sizeInc}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={appreciated}
                        onChange={(e) => setAppreciated(e.target.value)}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Form.Group>
                </div>

                <div>
                  <Form.Group className="mb-3 mx-2" controlId="formBasicEmail">
                    <Form.Label>Behavior</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your Comment"
                      value={sanitizeBehavior}
                      onChange={(e) => setBehavior(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                {/* <div className="w-100 d-flex justify-content-between mt-2">
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                  <Button className="dialog-btn" type="submit">
                    Submit
                  </Button>
                </div> */}
                <div className="test-TwoBtn">
                  <div>
                    {isDisabled ? (
                      <Button type="submit" className="dialog-btn" disabled>
                        Save
                      </Button>
                    ) : (
                      <Button type="submit" className="dialog-btn">
                        Save
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button
                      className="dialog-btn close-btn-abc"
                      onClick={() => handleCloseR()}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Row>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <div>
        {userData.length === 0 ? (
          <div className="ofsAssigned d-flex justify-content-end">
            <h4>No OFS Assigned</h4>
          </div>
        ) : (
          userData.map((data, i) => {
            console.log("data ====>", data);
            if (data.id == userId) {
              return (
                <div
                  key={i}
                  className="ofsAssigned"
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {data.ofsValue === 0 ? (
                    "Please Add OFS Value"
                  ) : (
                    <div>
                      {data.remainingOfs === 0 ? (
                        <div>
                          <span class="badge bg-success text-light">
                            OFS Target Acheived
                          </span>
                        </div>
                      ) : (
                        <Fragment>
                          <div>
                            <span class="badge bg-info text-dark">
                              OFS Assigned:{data.ofsValue}
                            </span>
                          </div>
                          <div>
                            <span class="badge bg-warning text-dark">
                              OFS Remaining:{data.remainingOfs}
                            </span>
                          </div>
                          <div>
                            <span class="badge bg-dark text-light">
                              OFS Month:
                              {moment(data.ofsDate, "DD-MM-YYYY").format("M")}
                            </span>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
              );
            }
          })
        )}
        {/* {  null } */}
      </div>
      <div className="card-container">
        {/* {role == 1 
        ? */}
        {/* <div>
        <Link to="/Dashboard/addFeedback" style={{ textDecoration: "none" }}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.cardmedia}
                component="img"
                alt="PAKAUTO"
                height="140"
                image="/icon/Safety.png"
                title="PakAuto Logo"
              />
              <CardContent className="card-text" className="card-text">
                <Typography
                  gutterBottom
                  variant="h4"
                  component="h4"
                  align="center"
                >
                  <span className="textSize">View Feedback</span>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </div> */}
        {/* : */}
        <div>
          <Link to="/Dashboard/addFeedback" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/Safety.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">View Feedback</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        {/* } */}
        <div>
          <Link
            to="/Dashboard/mainDashboard"
            style={{ textDecoration: "none" }}
          >
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/Analytics2-.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Dashboard</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        {role == "Admin" ? (
          <>
            <div>
              <Link
                to="/Dashboard/AdminCards"
                style={{ textDecoration: "none" }}
              >
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia
                      className={classes.cardmedia}
                      component="img"
                      alt="PAKAUTO"
                      height="140"
                      image="/icon/administrator.png"
                      title="PakAuto Logo"
                    />
                    <CardContent className="card-text">
                      <Typography
                        gutterBottom
                        variant="h4"
                        component="h4"
                        align="center"
                      >
                        <span className="textSize">Administrator</span>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </div>
          </>
        ) : // <div>
        //   <Card className={classes.root}>
        //   <Typography
        //     // gutterBottom
        //     // variant="h4"
        //     // component="h4"
        //     align="center"
        //     // style={{ marginTop: "0px", paddingTop: "0px" }}
        //   >
        //     <span className="textSize">User OFS</span>
        //     {userData.map((data, i) => {
        //       if (data.id == userId) {
        //         console.log(data);
        //         return (
        //           <div key={i} style={{ fontSize: "12px" }}>
        //             <div>ID:{data.id}</div>
        //             <div>UserName:{data.username}</div>
        //             <div>UserEmail:{data.email}</div>
        //             <div>
        //               OFS Value:{data.remainingOfs}/{data.ofsValue}
        //             </div>
        //             <div>OFS Date:{data.ofsDate}</div>
        //           </div>
        //         );
        //       }
        //     })}
        //   </Typography>
        //   </Card>
        // </div>
        null}
      </div>
    </div>
  );
}
