import React from 'react'

export default function comingSoon() {
    return (
        <div className="backgroundImage" 
        style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/images/coming-soon-page.png"})`,
            // backgroundSize: "cover",
            boxSizing: "inherit",
            // width: "100%",
           
          }}> 
        </div>
    )
}
