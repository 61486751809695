import React from "react";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
  root: {
    maxWidth: 280,
  },
  media: {
    height: 140,
  },
  cardmedia: {
    width: "120px",
    height: "120px",
    margin: "auto",
  },
});
export default function AdminCards() {
  console.clear()
  const history = useHistory();
  const classes = useStyles();
  return (
    <div style={{ height: "80vh" }}>
      <div className="centerHeading">
        {" "}
        <h1>ONGOING FEEDBACK SYSTEM</h1>{" "}
      </div>

      <div className="card-container">
        <div>
          <Link to="/Dashboard/Department" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/Production.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text" className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Department</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        <div>
          <Link to="/Dashboard/factor" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/factors.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text" className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Add Factor</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        <div>
          <Link to="/Dashboard/location" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/locations.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Add Location</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        {/* <div>
          <Link to="/Dashboard/departmentUsers" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/department.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Department Users</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div> */}
        <div>
          <Link to="/Dashboard/setOfs" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/PM.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Set OFS</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div>
        {/* <div>
          <Link to="/Dashboard/users" style={{ textDecoration: "none" }}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  alt="PAKAUTO"
                  height="140"
                  image="/icon/management.png"
                  title="PakAuto Logo"
                />
                <CardContent className="card-text" className="card-text">
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="h4"
                    align="center"
                  >
                    <span className="textSize">Users</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </div> */}
      </div>
    </div>
  );
}
