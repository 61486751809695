import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {FazerAnimation} from 'react-fazer-animation';
export const TSetter = (props) => {

    const history = useHistory()
   
    //using query-string lib to parse query string coming from landing page
    const parsed = queryString.parse(props.location.search);
    console.log(parsed.token)

    //Setting token in local Storage and Redirecting to dashboard

    //Checking Token
    if(!parsed.token){
        console.log('in if block')
    }
    //if token is avalaible then redirect to dashboard
    else{
        console.log('in else block')
        localStorage.setItem('token',parsed.token)
        history.push('/Dashboard')
    }

    // Funtion for redirecting
    const handleSignIn = () =>{
        window.location.href = 'https://finehhdigital.com/'
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <a style={{cursor:'pointer'}} href onClick={handleSignIn}>
        <FazerAnimation
          title={'Sign In To Continue...'}
          bodyColor={'cyan'}
          longfazersColor={'linear-gradient(black,red)'}
        />
        </a>
        
      </div>
    )
}
