import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
//Select imports from material ui
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";

import { toast } from "react-toastify";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "150%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));

const Users = () => {
  // console.clear()
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // states for department data
  const [department, setDepartment] = useState([]);

  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };
  // States
  const [usersData, setUsersData] = useState([]);
  // form states
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState();
  const [selectedDepart, setSelectedDepartment] = useState("");

  // Functions
  const getUsersData = async () => {
    let api = "/users/selectUser";
    let fd = await userRequestForOfs.get(api);
    setUsersData(fd.data.data);

    console.log("Users data ===>", fd.data.data);
  };

  //getting department data
  const getDepartments = async () => {
    let FD = await userRequestForOfs.get("/department/SelectDepartment");

    console.log("department dataaa=====>", FD.data.data);

    setDepartment(FD.data.data);
  };

  useEffect(() => {
    getUsersData();
    getDepartments();
  }, []);

  function formClear() {
    setUsername("");
    setEmail("");
    setPassword("");
    setAdmin("");
    setSelectedDepartment("");
  }

  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log(getCurrentMonth);

  //Form Submit Handler
  const submitHandler = (e) => {
    e.preventDefault();

    console.log("select dept ==========>", selectedDepart);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      toast.warning("Please fill all fields.");
      setIsDisabled(false);

      return;
    }
    // if (!username && email.length === 0 && password.length === 0 && admin === 3 && selectedDepart.length === 0) {
    //     toast.error("Please fill all fields")
    //     // alert("please write area name")
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);

    //     return;
    // }
    // if (admin === 3) {
    //     toast.error("Please select Admin field")
    //     return;
    // }
    // if(!email){
    //     toast.error("Please fill all fields")
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);

    //     return;
    // }
    // if(!username){
    //     toast.error("Please fill all fields")
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);

    //     return;
    // }
    // if(!password){
    //     toast.error("Please fill all fields")
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setValidated(true);

    //     return;
    // }
    if (!selectedDepart) {
      toast.error("Please fill all fields");
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);

      return;
    }
    setValidated(true);
    setIsDisabled(true);

    userRequestForOfs
      .post("/users/UserCreation", {
        email: email,
        username: username,
        password: password,
        isAdmin: admin,
        DepartName: selectedDepart,
        submittedBy: localStorage.getItem("username"),
      })
      .then((res) => {
        console.log("response from userCreation ============>", res);
        toast.success("Inserted successfully !");
        setIsDisabled(false);

        getUsersData();

        if (res.data.results === "Success") {
          setOpenR(false);
          formClear();
          getUsersData();
          setValidated(false);

          // console.log(tableDATA)
          console.log("respooooo", res);
        }
      })
      .catch((error) => {
        // error from catch
        console.log("error from catch ==>", error);
        if (error.message === "Network Error") {
          console.log("i am here");
          toast.error("Server Error!");
          setIsDisabled(false);
          return;
        } else if (error.response.status === 409) {
          toast.error("User Already Exist");
          setIsDisabled(false);
          return;
        } else {
          toast.error("Error !");
          return;
        }
      });
  };
  var DepartmentNameObject = department.reduce(function (acc, cur, i) {
    acc[cur.DepartName] = cur.DepartName;
    return acc;
  }, {});
  console.log(DepartmentNameObject);
  return (
    <div className="DHContainer">
      <div>
        <Button
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Users
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
              width: "600px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            {/* <img
                            src="/icon/alert1.png"
                            alt="alertimage"
                            width="60px"
                            height="50px"
                        ></img> */}
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Add Users
            </Typography>
          </DialogTitle>

          <DialogContent>
            {/* <form className="dhForm" noValidate onSubmit={submitHandler}>
                            <div className="div1t6">
                                <div>
                                    <FormControl required className={classes.formControl}>
                                        <TextField type="text" placeholder='Enter user Name'></TextField>
                                        <TextField type="email" placeholder='Enter user email'></TextField>
                                        <TextField type="password" placeholder='Enter user password'></TextField>
                                    </FormControl>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    endIcon={<SendIcon />}
                                >
                                    Save
                                </Button>
                            </div>
                        </form> */}
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Row className="mb-3">
                {/* <Form.Group as={Col} md="8" className='w-100' controlId="validationCustom03"> */}
                {/* <Form.Label>Name :</Form.Label> */}
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom01"
                >
                  <Form.Label>Name :</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    className="w-100"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom02"
                >
                  <Form.Label>Email :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="email"
                    placeholder="Email"
                    className="w-100"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom03"
                >
                  <Form.Label>Password :</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    placeholder="password"
                    className="w-100"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom01"
                >
                  <FormControl
                    required
                    className={`w-100 ${classes.formControl}`}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="text-gray"
                    >
                      Department Name
                    </InputLabel>
                    <Select
                      className={classes.sizeInc}
                      className={`w-100 ${classes.sizeInc}`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDepart}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                    >
                      {department.map((item) => (
                        <MenuItem value={item.DepartName}>
                          {item.DepartName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Form.Group>
                <div>
                  <FormControl
                    required
                    className={`w-100 ${classes.formControl}`}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="text-dark"
                    >
                      Admin?
                    </InputLabel>
                    <Select
                      className={classes.sizeInc}
                      className={`w-100 ${classes.sizeInc}`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={admin}
                      onChange={(e) => setAdmin(e.target.value)}
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"User"}>User</MenuItem>
                      <MenuItem value={"SuperUser"}>Super User</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Row>
              {/* <div className='w-100 d-flex justify-content-between'>
                                <Button
                                    className='dialog-btn close-btn-abc'
                                    onClick={() => handleCloseR()}
                                >
                                    Close
                                </Button>
                                <Button
                                    className='dialog-btn'
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div> */}
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      <div style={{ maxWidth: "100%", marginTop: "1rem" }}>
        <MaterialTable
          title="Users"
          columns={[
            { field: "username", title: "User Name" },
            { field: "email", title: "User Email" },
            {
              field: "DepartName",
              title: "Department Name",
              lookup: DepartmentNameObject,
            },
            {
              field: "isAdmin",
              title: "User Role",
              lookup: { Admin: "Admin", User: "User", SuperUser: "SuperUser" },
            },
          ]}
          data={usersData}
          //update and delete work

          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...usersData];
                  const index = oldData.id;

                  // console.log(index)
                  dataUpdate[index] = newData;
                  userRequestForOfs
                    .post("/users/updateUser", newData)
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("updatedSuccessfully");
                        console.log(newData);
                        getUsersData();
                        toast.success("Updated Successfully");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false);
                        return;
                      } else if (error.response.status === 504) {
                        console.log("i am setofs error", error.message);
                        toast.error("Please Set OFS for this Department");
                        return;
                      } else if (error.response.status === 409) {
                        toast.error("User Already Exist");
                        setIsDisabled(false);
                        return;
                      } else {
                        toast.error("Error");
                        return;
                      }
                    });
                  // setCauseData([...dataUpdate]);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  userRequestForOfs
                    .post("/users/deleteUser", {
                      id: index,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("updatedSuccessfully");
                        // console.log(newData);
                        getUsersData();
                        toast.info("Delete successfully !");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("i am here");
                        toast.error("Server Error!");
                        setIsDisabled(false);
                        return;
                      } else {
                        toast.error("Error !");
                        return;
                      }
                    });

                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            exportButton: true,
            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
            headerStyle: {
              backgroundColor: "#77C9C1",
              color: "black",
            },
          }}
        />
      </div>
    </div>
  );
};

export default Users;
