// import axios from "axios";
// export const baseURL = "https://ofs-b.finehhdigital.com/api";
// export const baseURL = "http://localhost:5000/api/";
// export const baseURL1 = "https://finehhb.finehhdigital.com/api";

// const token = localStorage.getItem("token");

// export const userRequestForOfs = axios.create({
//   baseURL: baseURL,
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });

// export const userRequestForUsers = axios.create({
//   baseURL: baseURL1,
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
// });

import axios from "axios";
import { toast } from "react-toastify";

export const baseURL = "https://backup-ofs-b.finehhdigital.com/api";
// export const baseURL = "http://localhost:5000/api/";
export const baseURL1 = "https://backup-ad-b.finehhdigital.com/api";

export const userRequestForOfs = axios.create({
  baseURL: baseURL,
});

export const userRequestForUsers = axios.create({
  baseURL: baseURL1,
  headers: {},
});

// Add a request interceptor to include the Authorization header with the token
userRequestForOfs.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
userRequestForUsers.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors
userRequestForOfs.interceptors.response.use(
  (response) => {
    console.log("response==>", response);
    return response;
  },
  (error) => {
    console.log("error  api routes ", error);
    if ("Request failed with status code 401") {
      // Check for token expiration
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      toast.error("Token Expired");
      // Redirect to the sign-in page
      window.location.href = "https://finehhdigital.com/signin";
    }
    return Promise.reject(error);
  }
);

userRequestForUsers.interceptors.response.use(
  (response) => {
    console.log("response==>", response);
    return response;
  },
  (error) => {
    console.log("error  api routes ", error);
    if ("Request failed with status code 401") {
      // Check for token expiration
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      toast.error("Token Expired");
      // Redirect to the sign-in page
      window.location.href = "https://finehhdigital.com/signin";
    }
    return Promise.reject(error);
  }
);
