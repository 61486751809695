import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
//Select imports from material ui
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { toast } from "react-toastify";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";
const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  sizeInc: {
    width: "150%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
}));
export default function DepertmentUsers() {
  console.clear();
  const [users, setUsers] = useState([]);
  const [department, setDepartment] = useState([]);
  const [usersDepartmentData, setUsersDepartmentData] = useState([]);

  //states variable
  const [email, setEmails] = useState("");
  const [selectedDepart, setSelectedDepartment] = useState("");

  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  useEffect(() => {
    getUsers();
    getDepartments();
    getUserDepartmentData();
  }, []);

  // Data fetching functions

  //for users
  const getUsers = async () => {
    let FD = await userRequestForOfs.get(
      "/users/getUsers"
    );
    console.log("users", FD.data);
    setUsers(FD.data);
  };
  //for Department

  const getDepartments = async () => {
    let FD = await userRequestForOfs.get(
      "/department/SelectDepartment"
    );

    setDepartment(FD.data.data);
  };
  //userdepartment

  const getUserDepartmentData = async () => {
    let FD = await userRequestForOfs.get(
      "/UserDepartment/selectUserDept"
    );
    console.log("UseradeptartmentDataaa==>", FD.data.data);
    setUsersDepartmentData(FD.data.data);
  };

  function formClear() {
    setEmails("");
    setSelectedDepartment("");
  }

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (!email) {
      toast.error("Please select email!");
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);

      return;
    }
    if (!selectedDepart) {
      toast.error("Please select department !");
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);

      return;
    }

    console.log("email and depart", email, selectedDepart);
    setValidated(true);
    setIsDisabled(true);

    userRequestForOfs
      .post(
        "/UserDepartment/createUserDept",
        {
          UserId: email,
          DepartName: selectedDepart,
          // UserName: selectedDepart,
          // UserName: selectedDepart,
        }
      )
      .then((res) => {
        toast.success("Inserted successfully !");
        console.log(res);
        setIsDisabled(false);
        getUserDepartmentData();
        // toast.success(res.data.message);
        if (res.status === 200) {
          setOpenR(false);
          formClear();
          getUserDepartmentData();
          getUsers();
          getDepartments();
          setValidated(false);
          console.log("ddddddddddddd" + res.status);
          console.log(res.data.message);
        } else {
          setOpenR(true);
          console.log(res.data.message);
          toast.error("Error !");
        }
      })
      .catch((error) => {
        console.log("error from catch", error);
        toast.error("Duplicate Entry");
      });
  };

  return (
    <div className="DHContainer">
      <div>
        <Button
          type="button"
          onClick={handleOpenR}
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add Users In Department
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={openR}
          onClose={handleCloseR}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            style: {
              // background: "linear-gradient(to right, #e0eafc, #cfdef3)"
              background: "linear-gradient(to right, #e0eafc, #cfdef3)",
              width: "600px",
            },
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "black",
                fontWeight: "bolder",
                fontFamily: "ariel",
              }}
            >
              Add Department and Users
            </Typography>
          </DialogTitle>

          <DialogContent>
            {/* <form className="dhForm" noValidate onSubmit={submitHandler}>
                            <div className="div1t6">
                                <div>
                                    <FormControl required className={classes.formControl}>
                                        <TextField type="text" placeholder='Enter user Name'></TextField>
                                        <TextField type="email" placeholder='Enter user email'></TextField>
                                        <TextField type="password" placeholder='Enter user password'></TextField>
                                    </FormControl>
                                </div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    endIcon={<SendIcon />}
                                >
                                    Save
                                </Button>
                            </div>
                        </form> */}
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Row className="mb-3">
                {/* <Form.Group as={Col} md="8" className='w-100' controlId="validationCustom03"> */}
                {/* <Form.Label>Name :</Form.Label> */}

                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom01"
                >
                  <FormControl
                    required
                    className={`w-100 ${classes.formControl}`}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="text-dark"
                    >
                      User Email
                    </InputLabel>
                    <Select
                      // className={classes.sizeInc}
                      className={`w-100 ${classes.sizeInc}`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={email}
                      onChange={(e) => setEmails(e.target.value)}
                    >
                      {/* <MenuItem value={1}>Abdul Rehman</MenuItem> */}
                      {users.map((item) => (
                        <MenuItem value={item.id}>
                          {item.email}({item.username})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="8"
                  className="w-100"
                  controlId="validationCustom01"
                >
                  <FormControl
                    required
                    className={`w-100 ${classes.formControl}`}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className="text-dark"
                    >
                      Department Name
                    </InputLabel>
                    <Select
                      // className={classes.sizeInc}
                      className={`w-100 ${classes.sizeInc}`}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedDepart}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                    >
                      {department.map((item) => (
                        <MenuItem value={item.DepartName}>
                          {item.DepartName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Form.Group>
              </Row>
              {/* <div className='w-100 d-flex justify-content-between'>
                                <Button
                                    className='dialog-btn close-btn-abc'
                                    onClick={() => handleCloseR()}
                                >
                                    Close
                                </Button>
                                <Button
                                    className='dialog-btn'
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div> */}
              <div className="test-TwoBtn">
                <div>
                  {isDisabled ? (
                    <Button type="submit" className="dialog-btn" disabled>
                      Save
                    </Button>
                  ) : (
                    <Button type="submit" className="dialog-btn">
                      Save
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="dialog-btn close-btn-abc"
                    onClick={() => handleCloseR()}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
      <div style={{ maxWidth: "100%", marginTop: "1rem" }}>
        <MaterialTable
          title="Users and Departments"
          columns={[
            // {
            //     field: "id",
            //     title: "ID",
            //     cellStyle: {
            //         backgroundColor: "#74F700",
            //         color: "#FFF",
            //     },
            //     headerStyle: {
            //         backgroundColor: "#74F700",
            //     },
            // },
            // { field: "email", title: "User Email" },
            { field: "userName", title: "User Name", editable: "never" },
            { field: "userEmail", title: "User Email", editable: "never" },
            {
              field: "DepartName",
              title: "Department Name",
              editable: "never",
            },
          ]}
          data={usersDepartmentData}
          editable={{
            // onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve, reject) => {
            //         setTimeout(() => {
            //             const dataUpdate = [...usersDepartmentData];
            //             const index = oldData.id;

            //             // console.log(index)
            //             dataUpdate[index] = newData;
            //                 .post(
            //                     "https://ofs-b.finehhdigital.com/api/UserDepartment/updateUserDept",
            //                     newData
            //                 )
            //                 .then((res) => {
            //                     if (res.status === 200) {
            //                         console.log("updatedSuccessfully");
            //                         getUserDepartmentData();

            //                         console.log(newData);
            //                     } else {
            //                         console.log(res.data.message);
            //                         console.log(newData);
            //                         toast.error("Error !");

            //                     }
            //                 });
            //             // setCauseData([...dataUpdate]);
            //             resolve();
            //         }, 1000);
            //     }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  userRequestForOfs
                    .post(
                      "/UserDepartment/deleteUserDept",
                      {
                        id: index,
                      }
                    )
                    .then((res) => {
                      if (res.status === 200) {
                        // console.log("updatedSuccessfully");
                        // console.log(newData);
                        getUserDepartmentData();
                        toast.info("Delete successfully !");
                      } else {
                        console.log(res.data.results);
                        // console.log(newData);
                        toast.error("Error !");
                      }
                    });

                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            exportButton: true,
            // pageSizeOptions: [5, 10, 20,{ value: tableDATA.data.length, label: 'All' }],
            headerStyle: {
              backgroundColor: "#77C9C1",
              color: "black",
            },
          }}
        />
      </div>
    </div>
  );
}
