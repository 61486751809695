import React, { useState, useEffect } from "react";
import moment from "moment";
import jwt from "jsonwebtoken";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
//Select imports from material ui
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  Form,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  InputGroup,
} from "react-bootstrap";
import { userRequestForOfs } from "../apiRoutes/apiRoutes";

const useStyles = makeStyles((theme) => ({
  modal: {
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    // minWidth: "65%",
    // minWidth: "120",
    // maxWidth: 600
    width: "100%",
    // marginLeft: 0
  },
  sizeInc: {
    width: "100%",
  },
  buttonSubmit: {
    //    marginTop:'150px'
  },
  paper: {
    height: "auto",
    // width: '90%',
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "linear-gradient(to bottom, #abbaab, #ffffff)",
    border: "2px solid #000",
    borderRadius: "1rem",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    top: "50px",
    left: "30%",
    marginTop: "-50px",
    marginLeft: "-50px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "3",
    float: "right",
  },
  root: {
    "& .super-app-theme--header": {
      backgroundColor: "black",
      color: "white",
    },
  },
  roots: {
    "& > *": {
      margin: theme.spacing(1),
      display: "flex",
      // border: '1px solid black',
    },
  },
}));

export default function AddFeedback() {
  // console.clear();
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openR, setOpenR] = React.useState(false);
  const handleOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };

  const [feedbackData, setFeedbackData] = useState([]);
  // form states
  const [validated, setValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [response, setResponse] = useState("");
  const [comments, setComments] = useState("");
  // newFields
  const [instructorDept, setInstructorDept] = useState("");
  const [behaviorDept, setBehaviorDept] = useState();
  const [location, setLocation] = useState();
  const [locationData, setLocationData] = useState([]);
  const [behavior, setBehavior] = useState();
  const [factor, setFactor] = useState();
  const [factorData, setFactorData] = useState([]);
  const [appreciated, setAppreciated] = useState();
  const [userId, setUserId] = useState();
  //for getting
  const [reTo, setreTo] = useState([]);
  const [depData, setDepData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userRole, setUserRole] = useState();
  const [userFeedbackData, setUserFeedbackData] = useState([]);
  console.log(userRole);
  //for filter data

  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedDate2, setSelectedDate2] = useState(moment());

  //reset btn show or !
  const [btnShow, setBtnShow] = useState(false);

  // for bootstrap alert
  const [isAlert, setIsAlert] = useState(false);

  const getFeedback = async () => {
    let api = "/feedback/getFeedback";
    const fetchedData = await userRequestForOfs.get(api);
    console.log("get feedback data ===>", fetchedData.data.data);
    setFeedbackData(fetchedData.data.data);
  };

  //for getting department data
  const getDepData = async () => {
    let FD = await userRequestForOfs.get(
      "/department/SelectDepartment"
    );
    console.log("relatedto==>", FD.data.data);
    setDepData(FD.data.data);
  };
  const getFactor = async () => {
    let FD = await userRequestForOfs.get(
      "/factors/selectfactor"
    );
    console.log("getfactorssdata==>", FD.data.data);
    setFactorData(FD.data.data);
  };
  const getLocation = async () => {
    let FD = await userRequestForOfs.get(
      "/location/selectLocation"
    );
    console.log("getLocation data==>", FD.data.data);
    setLocationData(FD.data.data);
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    let userIDFromToken = decoded.payload.id;
    let userRole = decoded.payload.ofsRole;
    getFeedback();
    getDepData();
    getFactor();
    getLocation();
    setUserId(userIDFromToken);
    setUserRole(userRole);
    getUserIndividualData();
    getUserIndividualFeedback();
  }, [userId]);

  function formClear() {
    // setDepart("");
    setInstructorDept("");
    setResponse("");
    // setComments("");
    setAppreciated("");
    setFactor("");
    setLocation("");
    setBehavior("");
    setBehaviorDept("");
    getUserIndividualData();
    // setFactorData([])
    // setLocationData([])
  }

  //Month Work
  let MonthArr = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let getCurrentMonth = MonthArr[new Date().getMonth()];
  console.log(getCurrentMonth);

  //Individual User
  const getUserIndividualData = async () => {
    await userRequestForOfs
      .post("/users/userIndividualData", {
        id: userId,
      })
      .then((res) => {
        // toast.success("Inserted successfully !");
        console.log("response from getUserIndividualData ====>", res);
        if (res.status === 200) {
          // console.log(res.data)
          setUserData(res.data.data);
          console.log("with data.data", res.data.data);
          // console.log(res.data.message);
          // console.log("ddddddddddddd" + res.status);
        } else {
          console.log(res.data.message);
          // toast.error("Error !");
        }
      });
  };
  //Individual User feedbackData
  const getUserIndividualFeedback = async () => {
    await userRequestForOfs
      .post(
        "/feedback/getIndividualFeedback",
        {
          userId: userId,
        }
      )
      .then((res) => {
        // toast.success("Inserted successfully !");
        console.log("response from getUserIndividualData ====>", res);
        if (res.status === 200) {
          // console.log(res.data)
          setUserFeedbackData(res.data.data);
          console.log("user feedback data", res.data.data);
          // console.log(res.data.message);
          // console.log("ddddddddddddd" + res.status);
        } else {
          console.log(res.data.message);
          // toast.error("Error !");
        }
      });
  };

  //monthly ofs Complete Alert
  // const getAlert = () => {
  //   return (
  //     <div className="alert alert-danger" role="alert">
  //       This is a danger alert—check it out!
  //     </div>
  //   );
  // };
  const getAlert = (
    <div className="alert alert-danger" role="alert">
      This is a danger alert—check it out!
    </div>
  );

  // Checking UserData ofs Remaining Value

  let UserOfsLeft = userData.map((item) => {
    var ofsValue = item.remainingOfs;
    return ofsValue;
  });
  console.log(UserOfsLeft[0]);
  //Form Submit Handler
  const submitHandler = (e) => {
    console.log("checkingValue", UserOfsLeft[0]);
    //  Checking OFS value
    if (UserOfsLeft[0] > 0) {
      e.preventDefault();

      if (
        !instructorDept ||
        !location ||
        !factor ||
        !behaviorDept ||
        !response ||
        !appreciated ||
        !behavior
      ) {
        toast.warn("Fill All Fields");
        return;
      }

      // OfsValue++
      // console.log("ofsValue -===> ", OfsValue);

      // count++;
      // console.log("testing", count);
      setIsDisabled(true);

      userRequestForOfs
        .post("/feedback/sendFeedback", {
          // department: depart,
          location: location,
          factor: factor,
          instructorDept: instructorDept,
          behaviorDept: behaviorDept,
          appreciated: appreciated,
          // comments: comments,
          behavior: behavior,
          response: response,
          submittedBy: localStorage.getItem("username"),
          submittedMonth: getCurrentMonth,
          ofsValue: 1,
          id: userId,
        })
        .then((res) => {
          console.log("response from server", res);
          setIsDisabled(false);
          // getFeedback();
          if (res.status === 200) {
            toast.success("Feedback insert successfully ");

            setOpenR(false);
            getFeedback();
            formClear();
            // console.log(tableDATA)
            console.log(res.data.message);
            console.log("ddddddddddddd" + res.status);
          } else if (res.status === 403) {
            toast.warning("your ofs completed");
            setOpenR(false);
            formClear();
            console.log(res.data.message);
          }
          // else if(res.message==='Network Error'){
          //   console.log("messagggeeee",res.message);
          //   setIsDisabled(false)
          //   toast.error('Network Error')}
          else {
            setOpenR(true);
            console.log(res.data.message);
          }
        })
        .catch((res) => {
          if (res.message === "Network Error") {
            toast.error("Server Error!");
            setIsDisabled(false);
            setOpenR(true);
          }
        });
    } else {
      toast.warn("OFS Monthly Target Achieved");
      console.log("test before");
      <AddFeedback />;
      getFeedback();
      console.log("test after");
      setIsAlert(true);
      setTimeout(() => {
        setIsAlert(false);
      }, 5000);
      // alert("wait")
      // getAlert();
    }
  };

  // Filter work Start

  const handleDateChange = (date) => {
    // console.log("values from prop ====>",date);

    // let changeDateFormat = moment(date).format('DD-MM-YYYY')
    let changeDateFormat = moment(date).format("YYYY-MM-DD");
    // console.log('changeDateFormat from start =====>', changeDateFormat)
    setSelectedDate(changeDateFormat);
  };

  const handleDateChange2 = async (date) => {
    // console.log("values from prop ====>",date);

    // let changeDateFormat = moment(date).format('DD-MM-YYYY')
    let changeDateFormat = moment(date).format("YYYY-MM-DD");

    // console.log('chnageDate formate from end =====>', changeDateFormat)
    setSelectedDate2(changeDateFormat);
    // submitFilter()
    if (!selectedDate && !changeDateFormat) {
      toast.warning("Please Select Date");
      return;
    }

    console.log("start date ====>", selectedDate);
    console.log("end date ====>", changeDateFormat);

    // filterDate

    userRequestForOfs
      // .post("https://ofs-b.finehhdigital.com/api/feedback/filterDate", {
      .post("/feedback/filterDate", {
        startDate: selectedDate,
        endDate: changeDateFormat,
      })
      .then((response) => {
        console.log("response from filterDate ,====>", response);
        setFeedbackData(response.data.data);
        setBtnShow(true);
      })
      .catch((error) => {
        console.log("error from filterDate", error);
        // toast.error("error from filterDate")
      });
  };
  // Filter work End

  //For date Reset
  const handleReset = () => {
    console.log("handle reset ==================>");
    getFeedback();
    setSelectedDate(moment());
    setSelectedDate2(moment());
    setBtnShow(false);
  };
  const handlePageRedirect = () => {
    window.location.href = "https://finehhdigital.com/home";
  };
  return (
    <div className="w-90 DHContainer">
      {isAlert ? getAlert : null}
      <div>
        <div className="breadcrumb" onClick={handlePageRedirect}>
          <div>
            <img src="/icon/home1.png" alt="homeLogo"></img>
          </div>
          <div>Home</div>
        </div>
        <div style={{ width: "95%" }}>
          <div className="row w-100 d-flex justify-content-between p-0 m-0">
            <div className="col-md-6 d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center Heading">
                {/* <h3 className='fw-bold fst-italic fs-2 Text-Gradient-Color'>Filter By Date </h3> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-10">
                  <h6 className="fw-bold text-center">Filter By Date </h6>
                  <div>
                    <form className={`${classes.roots}`} noValidate>
                      {/* <form className={`border border-dark rounded ${classes.roots}`} noValidate> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          // helperText={''}
                          margin="normal"
                          id="date-picker-dialog"
                          label="Start Date"
                          format="yyyy-MM-dd"
                          // format="dd-MM-yyyy"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </form>
                  </div>
                  <div>
                    <form className={classes.roots} noValidate>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-picker-dialog2"
                          label="End Date"
                          format="yyyy-MM-dd"
                          value={selectedDate2}
                          onChange={handleDateChange2}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </form>
                  </div>
                </div>
                {btnShow ? (
                  <div className="col-md-2 icon-size">
                    <button
                      className="btn btn-danger mt-3"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        {userRole == "Admin" ? (
          <MaterialTable
            className="resp-for-mobile"
            style={{
              marginTop: "10px",
              whiteSpace: "nowrap",
              // maxWidth: "23%",
              tableLayout: "fixed",
            }}
            title="Feedback"
            columns={[
              { field: "instructorDept", title: "Instructor Belongs" },
              { field: "behaviorDept", title: "Behaviour Found " },
              { field: "location", title: "Location" },
              { field: "factor", title: "Factor" },
              {
                field: "response",
                title: "Safe/UnSafe",
                lookup: { Safe: "Safe", Unsafe: "Unsafe" },
              },
              { field: "submittedOn", title: "Date" },
              { field: "submittedBy", title: "Submitted By" },
              { field: "appreciated", title: "Appreciated" },
              { field: "behavior", title: "Behaviour" },
            ]}
            data={feedbackData}
            options={{
              filtering: true,
              exportButton: true,
              pageSizeOptions: [
                5,
                10,
                20,
                { value: feedbackData.length, label: "All" },
              ],
              headerStyle: {
                backgroundColor: "#77C9C1",
                color: "black",
              },
            }}
          />
        ) : (
          <MaterialTable
            className="resp-for-mobile"
            style={{
              marginTop: "10px",
              whiteSpace: "nowrap",
              // maxWidth: "23%",
              tableLayout: "fixed",
            }}
            title="Feedback"
            columns={[
              { field: "instructorDept", title: "Instructor Belongs" },
              { field: "behaviorDept", title: "Behaviour Found " },
              { field: "location", title: "Location" },
              { field: "factor", title: "Factor" },
              {
                field: "response",
                title: "Safe/UnSafe",
                lookup: { Safe: "Safe", Unsafe: "Unsafe" },
              },
              { field: "submittedOn", title: "Date" },
              { field: "appreciated", title: "Appreciated" },
              { field: "behavior", title: "Behaviour" },
            ]}
            data={userFeedbackData}
            options={{
              filtering: true,
              exportButton: false,
              pageSizeOptions: [
                5,
                10,
                20,
                { value: userFeedbackData.length, label: "All" },
              ],
              headerStyle: {
                backgroundColor: "#77C9C1",
                color: "black",
              },
            }}
          />
        )}
      </div>
    </div>
  );
}
