import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
//icons
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
// import EqualizerIcon from '@material-ui/icons/Equalizer';
//list

import {
  Switch,
  Route,
  Link,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
// import DefactsHandling from "./AddFeedback";
// import DefectHandlingDashboard from "./ViewFeedback";
import Cards from "./Cards";

// import SignIn from '../SignIn';
import jwt from "jsonwebtoken";

import AddFeedback from "./AddFeedback";
import ViewFeedback from "./ViewFeedback";
import comingSoon from "./comingSoon";
import AdminCards from "./AdminCards";
import { Department } from "./Department";
import Users from "./Users";
import AddFactor from "./AddFactor";
import AddLocation from "./AddLocation";
import SetOfs from "./SetOfs";
import DepertmentUsers from "./DepertmentUsers";
// import MainDashboard from './MainDashboard/MainDashboard'
// import DepartRecord from './MainDashboard/DepartRecord'
// import OFSRecord from './MainDashboard/OFSRecord'
// import UserScore from './MainDashboard/UserScore'
// import DepartLevel from './MainDashboard/DepartLevel'

//logo
import logo from "../assets/logo.png";
import logout from "../assets/logout.png";
import { PowerBiDashboard } from "./PowerBIDashbaord/PowerBiDashboard";

const drawerWidth = 380;

const useStyles = makeStyles((theme) => ({
  // rootTest: {
  //     flexGrow: 1,
  // },
  menuButtonTest: {
    marginRight: theme.spacing(2),
  },
  titleTest: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
    height: "fit-content",
  },
  appBar: {
    // background: '#2E3B55',
    // background: 'red',
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // background: '#1B2530',
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: "#F0F2F5",
  },
  test: {
    flexGrow: 1,
  },
}));

export default function MiniDrawer(props) {
  console.clear();
  const match = useRouteMatch();
  const history = useHistory();
  // console.log(props);
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [listOpen1, setListOpen1] = React.useState(false);
  const [listOpen2, setListOpen2] = React.useState(false);
  const [listOpen3, setListOpen3] = React.useState(false);
  const [listOpen4, setListOpen4] = React.useState(false);
  const [username, setUserName] = React.useState("");

  //userRole
  const [role, setRole] = useState();
  // console.log("role ==> ", role);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleListClick1 = () => {
    setListOpen1(!listOpen1);
  };
  const handleListClick2 = () => {
    setListOpen2(!listOpen2);
  };
  const handleListClick3 = () => {
    setListOpen3(!listOpen3);
  };
  const handleListClick4 = () => {
    setListOpen4(!listOpen4);
  };
  const goBack = () => {
    history.goBack();
  };
  const goForward = () => {
    history.goForward();
  };
  function decoder() {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });

    if (decoded == null) {
      history.push("/signin");
    } else {
      // console.log("decoded data ==>",decoded.payload);
      localStorage.setItem("username", decoded.payload.username);
      // localStorage.setItem("id", decoded.payload.id);
      // localStorage.setItem("isAdmin", decoded.payload.isAdmin)
      // localStorage.setItem("ofsValue", decoded.payload.ofsValue)
      var user = localStorage.getItem("username");
      return user;
    }
  }

  const logoutHandle = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("username")) {
      console.log("here");
    } else {
      window.location.href = "https://finehhdigital.com/signin";
    }
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    // console.log(decoded);
    setRole(decoded.payload.ofsRole);
    decoder();
  }, []);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <div className={classes.rootTest}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={`menu-icon ${clsx(classes.menuButton, {
                [classes.hide]: open,
              })}`}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleTest}>
              {/* <div className='resp-navbar row align-items-center'> */}
              <div className="resp-navbar row align-items-center">
                <div className="col-sm-2  navigation-view ">
                  <div className="d-flex justify-content-center">
                    <div>
                      <Button onClick={goBack}>
                        <ArrowBackIos style={{ fill: "white" }} />
                      </Button>
                    </div>
                    <div>
                      <Button onClick={goForward}>
                        <ArrowForwardIos style={{ fill: "white" }} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="resp-child-element col-sm-7 navigation-text">
                  <Link
                    to="/Dashboard"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <img src={logo} alt="logo" width="100px" />
                  </Link>
                </div>
                <div className="resp-child-element col-sm-3 ">
                  <div className="d-flex justify-content-end align-items-center">
                    <Typography className="resp-font-size">
                      {decoder().toUpperCase()}
                    </Typography>
                    <Button onClick={logoutHandle} color="inherit">
                      <span className="logoText1">
                        <img src={logout} alt="logout" width="25px" />
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className="drawer">
        <Drawer
          // temporary
          variant="permanent"
          open={open}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {/* <ListItem button onClick={handleListClick1}>
              <ListItemIcon>
              
                <img
                  src="/icon/Shop_floor3.png"
                  width="25px"
                  height="23px"
                ></img>
              </ListItemIcon>
              <ListItemText primary="SHOP FLOOR" />
              {listOpen1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem> */}
            {/* <Collapse in={listOpen1} timeout="auto" unmountOnExit> */}
            <List component="div" disablePadding>
              {/* {role == 1 
            ? */}
              {/* <Link
            to={match.url + "/addFeedback"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <img
                  src="/icon/Safety.png"
                  width="25px"
                  height="23px"
                ></img>
              </ListItemIcon>
              <ListItemText secondary="View Feeback" />
            </ListItem>
          </Link>
            : */}
              <Link
                to={match.url + "/addFeedback"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <img
                      src="/icon/Safety.png"
                      width="25px"
                      height="23px"
                    ></img>
                  </ListItemIcon>
                  <ListItemText secondary="Add Feeback" />
                </ListItem>
              </Link>
              {/* } */}
            </List>
            {/* </Collapse> */}
            {role ? (
              <>
                <ListItem button onClick={handleListClick3}>
                  <ListItemIcon>
                    <img
                      src="/icon/administrator.png"
                      width="25px"
                      height="23px"
                    ></img>
                  </ListItemIcon>
                  <ListItemText primary="ADMINISTRATION" />
                  {listOpen3 ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={listOpen3} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <Link
                      to={match.url + "/Department"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/Production.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Department" />
                      </ListItem>
                    </Link>
                  </List>

                  <List component="div" disablePadding>
                    {/* <Link
                      to={match.url + "/users"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/profile.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Users" />
                      </ListItem>
                    </Link> */}
                  </List>
                  <List component="div" disablePadding>
                    <Link
                      to={match.url + "/factor"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/options.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Factor" />
                      </ListItem>
                    </Link>
                  </List>

                  <List component="div" disablePadding>
                    <Link
                      to={match.url + "/location"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/relation.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Location" />
                      </ListItem>
                    </Link>
                  </List>

                  <List component="div" disablePadding>
                    {/* <Link
                      to={match.url + "/departmentUsers"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/department.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Users/Department" />
                      </ListItem>
                    </Link> */}
                  </List>

                  <List component="div" disablePadding>
                    <Link
                      to={match.url + "/setOfs"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <img
                            src="/icon/PM.png"
                            width="25px"
                            height="23px"
                          ></img>
                        </ListItemIcon>
                        <ListItemText secondary="Set OFS" />
                      </ListItem>
                    </Link>
                  </List>
                </Collapse>
              </>
            ) : null}
          </List>
        </Drawer>
      </div>
      <main className={`w-75 main-container ${classes.content}`}>
        <div className={classes.toolbar} />

        {/* defining All routes here */}
        {role == "Admin" ? (
          <Switch>
            <Route exact path="/DASHBOARD">
              <Cards />
            </Route>

            <Route path={match.url + "/CARDS"} component={Cards} />

            <Route path={match.url + "/addfeedback"} component={AddFeedback} />
            <Route
              path={match.url + "/viewFeedback"}
              component={ViewFeedback}
            />
            <Route
              path={match.url + "/AnalysisDashboard"}
              component={comingSoon}
            />
            <Route path={match.url + "/AdminCards"} component={AdminCards} />
            <Route path={match.url + "/Department"} component={Department} />
            <Route path={match.url + "/users"} component={Users} />
            <Route path={match.url + "/factor"} component={AddFactor} />
            <Route path={match.url + "/location"} component={AddLocation} />
            <Route path={match.url + "/setOfs"} component={SetOfs} />
            <Route
              path={match.url + "/departmentUsers"}
              component={DepertmentUsers}
            />
            <Route
              path={match.url + "/mainDashboard"}
              component={PowerBiDashboard}
            />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/DASHBOARD">
              <Cards />
            </Route>
            <Route
              path={match.url + "/mainDashboard"}
              component={PowerBiDashboard}
            />
            <Route path={match.url + "/addfeedback"} component={AddFeedback} />
          </Switch>
        )}
      </main>
    </div>
  );
}
